import {initializeApp} from 'firebase/app';
import {connectAuthEmulator, getAuth} from "firebase/auth";
import {getFirestore, connectFirestoreEmulator} from "firebase/firestore";
import {connectFunctionsEmulator, getFunctions} from 'firebase/functions';
import { getStorage, connectStorageEmulator } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDbOlD0ygupgPBC7PBMmTeIIc1iAMqgD5A",
  authDomain: "clgmbh-portal.firebaseapp.com",
  projectId: "clgmbh-portal",
  storageBucket: "clgmbh-portal.appspot.com",
  messagingSenderId: "922131841128",
  appId: "1:922131841128:web:1371521e2ea07ecf3afbdd"
};

const region = 'europe-west3';

const firebase = initializeApp(firebaseConfig);
const auth = getAuth(firebase);
const db = getFirestore(firebase);
const functions = getFunctions(firebase, region);
const storage = getStorage(firebase);

if (location.hostname === 'localhost') {
  connectAuthEmulator(auth, 'http://localhost:9998');
  connectFirestoreEmulator(db, 'localhost', 8098);
  connectFunctionsEmulator(functions, 'localhost', 7003);
  connectStorageEmulator(storage, "localhost", 9299);
}

export {firebase, region, auth, functions, storage} 
