import { createRouter, createWebHistory } from "vue-router";

import Home from "../../components/HomeView"
import Settings from "../../components/SettingsView"
import Users from "../../components/UsersView"
import Login from "../../components/LoginView"
import store from "../store";
import PasswordResetRequest from "../../components/ResetPasswordRequest";

import NotAllowed from "../../components/NotAllowed"

//userManagement
import userManagement from "../../components/userManagement/UserManagement"

//404
import NotFound from "../../components/NotFound"

// import i18n from "../i18n";

import { auth } from "../firebase/index";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { 
      path: '/',
      redirect: '/login' 
    },
    { 
      path: '/:pathMatch(.*)*',
      component: NotFound,
      name: "NotFound",
    },
    {
      path: "/usermanagement",
      component: userManagement,
      name: "userManagement",
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/password-reset",
      component: PasswordResetRequest,
      name: "PasswordResetRequest",
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/notallowed",
      component: NotAllowed,
      name: "NotAllowed",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/home",
      component: Home,
      name: "Home",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/settings",
      component: Settings,
      name: "Settings",
      meta: {
        requiresAuth: true,
        onlyAdmin: true,
        onlySuperAdmin: true
      }
    },
    {
      path: "/Users",
      component: Users,
      name: "Users",
      meta: {
        requiresAuth: true,
        onlyAdmin: true,
        onlySuperAdmin: true
      }
    },
    {
      path: "/login",
      component: Login,
      name: "Login",
      meta: {
        requiresAuth: false
      }
    }
  ],
});

router.beforeEach(async (to, from, next) => {

  // let lang = to.params.lang;
  // if (!lang) {
  //   lang = 'en'
  // }
  // i18n.global.locale = lang

  const currentUser = store.state.user.loggedIn

  let tokens = currentUser ? await auth.currentUser.getIdTokenResult() : false
  let isUser = tokens ? tokens.claims?.active : false

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  if ((to.name === 'Login' || to.name === 'NotAllowed') && currentUser && isUser) next({ name: 'Home' })
  else if (to.name !== 'NotAllowed' && !isUser && currentUser && requiresAuth) next({ name: 'NotAllowed' })
  else if (requiresAuth && currentUser) next()
  else if (requiresAuth && !currentUser) next({ name: 'Login' })
  else next()
})

export default router;