<template>
  <div class="dropdown">
    <div style="cursor: pointer; d-flex" :ref="`dropdownMenu${id}`" data-bs-toggle="dropdown" @click="dropDownFlags.toggle()" aria-expanded="false">
      <font-awesome-icon style="height: 1.8rem;" class="align-middle" :icon="['fas', 'circle-user']" color="white"/> 
    </div>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton" >
      <li><h6 class="dropdown-header">{{$t('user.my_account')}}</h6></li>
      <div class="ms-3 me-3">
        <small> <b>{{$t('user.name')}}:</b><br> {{user.claims.name}}</small><br>
        <small> <b>{{$t('user.email')}}:</b><br> {{user.claims.email}}</small><br>
        <small> <b>{{$t('user.state')}}:</b><br> <State  :state="user.claims.state"/></small><br>
      </div>
      <li><hr class="dropdown-divider"></li>
      <li><span class="dropdown-item cpt" @click="this.$emit('edit', id)"><font-awesome-icon :icon="['fas', 'pen-to-square']" color="grey"/> {{$t('user.my_data')}}</span></li>
      <li><span class="dropdown-item cpt" @click="this.$emit('logout', id)"><font-awesome-icon :icon="['fas', 'sign-out']" color="grey"/> {{$t('user.sing_out')}}</span ></li>
    </ul>
  </div>
</template>

<script>
import { Dropdown } from "@comlinedev/bootstrap5"
import { mapGetters } from "vuex";
import State from "./StateComponent.vue"

export default {
  name: 'dropdownUserAccount',
  components:{
    State
  },
  props:{
    id: String
  },
  computed: {
    ...mapGetters({
      user: "user"
    }),
  },
  methods:{
  },
  emits: ['delete', 'edit'],
  data(){
    return {
      dropDown: null
    }
  },
  mounted(){
    this.dropDownFlags = new Dropdown(this.$refs[`dropdownMenu${this.id}`])
  }
}
</script>

<style scoped>
  .cpt {
    cursor: pointer;
  }
</style>
