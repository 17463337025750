<template>
  <div>
    <div class="d-flex justify-content-center align-items-center" style="width: 100%; height: 100%; position: absolute;">
      <div class="loginBox">
        <div class="d-flex justify-content-center align-items-center">
          <div id="logoBox">
          <Logo :fill="'#000000'" :height="'33px'" :width="'33px'" class="mb-0 me-2 d-flex align-items-center"></Logo> <span id="logotext"> Portal</span>
          </div>
        </div>
        <div v-if="error" class="d-flex alert alert-danger error">{{error}}</div>
        <form action="#" @submit.prevent="submit" v-if="mode === 'resetPassword'">
          <div>
            <div>
              <p>{{$t('user_management.input_a_password')}}</p>
              <label>{{$t('user_management.password')}}</label>
              <div class="input-group mb-3">
                <span class="input-group-text"><font-awesome-icon :icon="['fas', 'key']" /></span>
                <input                     
                  id="password"
                  type="password"
                  class="form-control"
                  name="password"
                  required
                  v-model="form.password"/>
              </div>
            </div>
          </div>
          <div class="form-group mt-4">
            <button type="submit" class="btn btn-secondary w-100">{{$t('user_management.save')}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {getAuth, confirmPasswordReset} from "firebase/auth";
import Logo from '../../assets/Logo'

export default {
  name: 'UserManagement',
  components:{
    Logo,
  },
  data() {
    return {
      form: {
        password: ""
      },
      oobCode: "",
      mode: "",
      lang: "",
      error: null
    };
  },
  methods: {
    submit() {
      console.log(this.oobCode)
      confirmPasswordReset(getAuth(), this.oobCode, this.form.password)
      .then(() => {
        this.$router.replace({ name: "Home" });
      })
      .catch(err => {
        this.error = err.message;
      });
    },
  },
  mounted(){
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.oobCode = urlParams.get('oobCode')
    this.mode = urlParams.get('mode')
  }

};
</script>
<style scoped>
  .loginBox {
    width: 400px; 
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 50px;
    border: 1px solid #dedede;
    border-radius: 0.35rem;
    border-top: 4px solid #4089c9; 
  }
  .input-group-text {
  width: 48px;
  border-right: none !important;
  background-color: #ffffff;
  }
  #email, #password {
    border-left: none; 
  }
  #logoBox{
    display: flex; 
    align-content: center;
    align-items: center;
    padding-bottom: 20px; 
    padding-right: 20px; 
  }
  #logotext {
    font-size: 30px;
  }
</style>