<template>
  <div class="fixed-top">
    <div class="container">

      <div class="m-3">
        <transition-group tag="div" class="VueOnly" name="fade" appear>
          <div v-for="error in errors" :key="error">
            <div class="alert alert-danger alert-dismissible" role="alert">
              <font-awesome-icon icon="fa-solid fa-triangle-exclamation" />
              {{error}}
              <button type="button" class="btn-close" @click="removeError(error)"></button>
            </div>
          </div>
        </transition-group>
      </div>

      <div class="m-3">
        <transition-group tag="div" class="VueOnly" name="fade" appear>
          <div v-for="success in successes" :key="success">
            <div class="alert alert-success alert-dismissible" role="alert">
              <font-awesome-icon icon="fa-solid fa-badge-check" />
              {{success}}
              <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="removeSuccess(success)"></button>
            </div>
          </div>
        </transition-group>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: 'ErrorSuccessMessage',
  data() {
    return {
      show: null
    }
  },
  computed: {
    ...mapState(["errors","successes"]),
  },
  methods:{
    removeError(error){
      const newErrors = this.errors.filter(item => item !== error)
      this.$store.commit('errors', newErrors)
    },
    removeSuccess(success){
      const newSuccess = this.successes.filter(item => item !== success)
      this.$store.commit('successes', newSuccess)
    }
  },
  unmounted() {
    this.$store.commit('errors', [])
    this.$store.commit('successes', [])
  },
}
</script>

<style scoped>
.fade-enter-active {
  animation: fade-in .5s;
}

.fade-leave-active {
  animation: fade-in 1s reverse;
}

@keyframes fade-in {
  from {
    opacity: 0;
    margin-top: -50px;
    margin-bottom: 50px;
  }
  to {
    opacity: 1;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
</style>
