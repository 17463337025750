import { createApp } from "vue";
import App from "./App.vue";
// import Loading from "./components/Loading";

import "@comlinedev/bootstrap5/dist/css/bootstrap.min.css";
import "@comlinedev/bootstrap5/dist/js/bootstrap.bundle.min.js";

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { firebase, region} from "./plugins/firebase";
import { getAuth, onAuthStateChanged} from "firebase/auth";

import router from "./plugins/router";
import i18n from "./plugins/i18n";
import store from "./plugins/store";

library.add(fas);

let app;

onAuthStateChanged(getAuth(), (user) => {
  
  store.dispatch("fetchUser", user)

  if (!app) {
    app = createApp(App)
          .component('font-awesome-icon', FontAwesomeIcon)
          .provide('$router', router)
          .provide('$firebase', firebase)
          .provide('$region', region)
          .use(store)
          .use(router)
          .use(i18n)
          .mount("#app");
  }

})