<template>
  <div>
    <button class="btn btn-link btn-sm" style="padding: 0 !important;" type="button" :ref="`dropdownMenu${uid}`" @click="modal.show()">
    <font-awesome-icon :icon="['fas', 'ellipsis-h']" color="grey"/> 
    </button>
    <div class="modal" :ref="`claimsModal${uid}`">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{$t('user.permission_for')}} - {{name}} -</h5>
            <button type="button" class="btn btn-close"  @click="modal.hide()" aria-label="Close" />
          </div>

          <div class="modal-body" v-if="claims" >
            <ul class="list-group">
              <li class="list-group-item text-left d-flex justify-content-between" v-for="(claim, index) in claims" :key="index" >

                <div class="form-check form-switch" v-if="!claim.required">
                  <input class="form-check-input" type="checkbox" :id="index" :ref="toolId+uid+index" :checked="userClaims?.[claim.key] ? true: false" @change="setClaim(claim)">
                  <label class="form-check-label" :for="index">{{claim.key}}</label>
                </div>

              </li>
            </ul>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="modal.hide()">{{$t('user.close')}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "@comlinedev/bootstrap5"
import { getFirestore, doc, updateDoc, deleteField, getDoc, setDoc} from "firebase/firestore"

export default {
  name: 'setupClaims',
  props: {
    uid: String,
    toolId: String,
    name: String,
    claims: Object,
  },
  methods: {
    async setClaim(claim){
      if(!this.userClaims?.[claim.key]){
        const ref = doc(doc(getFirestore(), "tools", this.toolId), "claims", this.uid)

        const docRef = await getDoc(ref);
        if (docRef.exists()) {

          await updateDoc(ref, {[claim.key]: claim.value})
          .then(()=>{
            this.userClaims[claim.key] = claim.value
          })
          .catch(err=>{      
            console.error(err)
            this.$store.commit('addError', err)
          })

        } else {
          await setDoc(ref, {[claim.key]: claim.value})
          .then(()=>{
            this.userClaims[claim.key] = claim.value
          })
          .catch(err=>{      
            console.error(err)
            this.$store.commit('addError', err)
          })

        }
      } else {
        await updateDoc(doc(doc(getFirestore(), "tools", this.toolId), "claims", this.uid), { [claim.key]: deleteField() })
        .then(()=>{
          delete this.userClaims[claim.key]
        })
        .catch(err=>{      
          console.error(err)
          this.$store.commit('addError', err)
        })
      }
    },
    async getUserClaims(){
      const ref = doc(doc(getFirestore(), "tools", this.toolId), "claims", this.uid)
      const docSnap = await getDoc(ref);
      this.userClaims = docSnap.data() || {}
    }
  },
  data(){
    return {
      userClaims: null,
    }
  },
  mounted(){
    this.getUserClaims()
    this.modal = new Modal(this.$refs[`claimsModal${this.uid}`])
  }
}
</script>
