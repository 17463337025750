<template>
  <div >
    <ErrorSuccessMessage style="z-index: 9999999;"/>
    <Nav />
    <router-view class="pt-5"/>
  </div>
</template>

<script>
import Nav from "./components/NavbarView"
import ErrorSuccessMessage from "./components/smallComponents/ErrorSuccessMessage.vue"
import { mapGetters } from "vuex";
import { auth } from "./plugins/firebase"
import detectBrowserLanguage from 'detect-browser-language'
import { getFirestore, doc, getDoc } from "firebase/firestore"


export default {
  name: "App",
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  data() {
    return {
      loading: true
    }
  },
  components: {
    Nav,
    ErrorSuccessMessage,
  },
  methods: {
    langDetect(){
      let lang = detectBrowserLanguage().split('-')[0]
      if (lang !== 'de' && lang !== "fr") return "en"
      return lang
    }
  },
  async mounted(){
    let lang 
    if(auth?.currentUser?.uid){
      await getDoc(doc(getFirestore(), "userLang", auth.currentUser.uid))
      .then(d=>{
        lang = d.data().lang
        this.loading = false
      })
      .catch((err)=>{
        console.log(err)
        this.loading = false
      })
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    lang = urlParams.get('lang') ? urlParams.get('lang') : lang;

    lang = lang ? lang : this.langDetect()
    auth.languageCode = lang;
    this.$i18n.locale = lang;
  }
};
</script>
