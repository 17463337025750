<template>
  <div>
    <Transition mode="out-in" name="fade">
      <img v-if="path" :src="path" class="img-fluid" id="preViewImage">
      <img v-else-if="!path && data.image" class="img-fluid" id="preViewImage" src="../../assets/temp.png">
    </Transition>
  </div>
</template>

<script>
import { storage } from "../../plugins/firebase"
import { ref as storageRef, getBlob } from "firebase/storage"

export default {
  name: 'imageBlob',
  data(){
    return{
      path: null,
    }
  },
  watch: {
    // data(newData) {
    //   this.getImage(newData.image)
    // console.log(newData.image)
    // }
  },
  props:{
    data: Object
  },
  methods: {
    async getImage(path){
      this.path = null
      await getBlob(storageRef(storage, path))
      .then(blob=>{
        this.path = URL.createObjectURL(blob)
      })
      return
    }
  },
  mounted(){
    this.getImage(this.data.image)
  }
}
</script>

<style scoped>
  #personImage { 
    position: absolute; 
    right: 0px; 
    top: 0px;  
    padding: 5px; 
    border: 1px solid #d3d3d3; 
    border-radius: 5px; 
    margin: 16px; 
    max-width: 240px;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
