<template>
  <div>
    <div class="d-flex justify-content-center align-items-center" style="width: 100%; height: 100%; position: absolute;">
      <div class="loginBox">
        <div class="d-flex justify-content-center align-items-center">
          <div id="logoBox">
          <Logo :fill="'#000000'" :height="'33px'" :width="'33px'" class="mb-0 me-2 d-flex align-items-center"></Logo> <span id="logotext"> Portal</span>
          </div>
        </div>
        <div v-if="error" class="d-flex alert alert-danger error">{{error}}</div>
        <form action="#" @submit.prevent="submit">
          <div class="form-group">
            <div>
              <label>{{$t('login.email')}}</label>
              <div class="input-group mb-3">
                <span class="input-group-text"><font-awesome-icon :icon="['fas', 'envelope']" /></span>
                <input                     
                  id="email"
                  type="email"
                  class="form-control"
                  name="email"
                  value
                  required
                  autofocus
                  v-model="form.email"/>
              </div>
            </div>
          </div>
          <div>
            <div>
              <label>{{$t('login.password')}}</label>
              <div class="input-group mb-3">
                <span class="input-group-text"><font-awesome-icon :icon="['fas', 'key']" /></span>
                <input                     
                  id="password"
                  type="password"
                  class="form-control"
                  name="password"
                  required
                  v-model="form.password"/>
              </div>
            </div>
          </div>
          <div class="form-group mt-4">
            <button type="submit" class="btn btn-primary w-100">{{$t('login.login')}}</button>
          </div>
        </form>
        <div class="text-center mt-3">
          <router-link  :class="`nav-link`" :to="`/password-reset`">{{$t('login.password_reset')}}</router-link >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getAuth, signInWithEmailAndPassword} from "firebase/auth";
import Logo from '../assets/Logo'

export default {
  name: 'LoginComponent',
  components:{
    Logo,
  },
  data() {
    return {
      form: {
        email: "",
        password: ""
      },
      error: null
    };
  },
  methods: {
    submit() {
      signInWithEmailAndPassword(getAuth(), this.form.email, this.form.password)
      .then(() => {
        this.$router.replace({ name: "Home" });
      })
      .catch(err => {
        this.error = err.message;
      });
    },
  },
  mounted(){
  }

};
</script>
<style scoped>
  .loginBox {
    width: 400px; 
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 50px;
    border: 1px solid #dedede;
    border-radius: 0.35rem;
    border-top: 4px solid #4089c9; 
  }
  .input-group-text {
  width: 48px;
  border-right: none !important;
  background-color: #ffffff;
  }
  #email, #password {
    border-left: none; 
  }
  #logoBox{
    display: flex; 
    align-content: center;
    align-items: center;
    padding-bottom: 20px; 
    padding-right: 20px; 
  }
  #logotext {
    font-size: 30px;
  }
</style>