<template>
  <div class="container">

    <Loading v-if="loading"  style="margin-top: 25px;"/>
    
    <div class="row mt-5" v-else-if="users.length <= 0">
      <div class="alert alert-warning" role="alert">
        Es wurden keine Nutzer angelegt.
      </div>
      <a href="#" class="btn btn-primary mt-5"  v-if="user.claims.state === 'superadmin' || user.claims.state === 'admin'" @click="openModalCreate()"><font-awesome-icon class="" :icon="['fas', 'plus']" /> {{$t('users.new_user')}}</a>
    </div>

    <div class="row mt-5 mb-5" v-else>
      <a href="#" class="btn btn-primary mb-5"  v-if="user.claims.state === 'superadmin' || user.claims.state === 'admin'" @click="openModalCreate()"><font-awesome-icon class="" :icon="['fas', 'plus']" />  {{$t('users.new_user')}}</a>
      <div class="input-group mb-3">
        <input class="form-control" id="search" placeholder="suche" v-model="search"/>
      </div>
      <div class="col-12">
        <ul class="list-group">
          <template  v-for="item in users" :key="item.uid">
          <li class="list-group-item text-left d-flex justify-content-between" v-if="item.displayName.toLowerCase().includes(search.toLowerCase())">
            {{item.displayName}}
            <div class="d-flex justify-content-end" >
              <State :state="item.customClaims?.state" class="pe-4"/>
              <DropDown 
                v-if="item?.customClaims"
                v-show="!(user.claims.state === 'admin' && item.customClaims.state === `superadmin`)"
                :id="item.uid" 
                @edit="(selectedUser=item.uid) && (this.state = item.customClaims.state) && (this.selectedUserName = item.displayName) && modalSttings.show()" 
                @delete="(selectedUser=item.uid) && modalDelete.show()"
                @password="(selectedUser=item.uid) && (passwordUser())"
              />
            </div>
          </li>
          </template>
        </ul>
      </div>
    </div>

    <div class="modal" ref="modalSttings">
      <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{$t('users.settings')}} - {{selectedUserName}} - </h5>
            <button type="button" class="btn btn-close"  @click="modalSttings.hide()" aria-label="Close" />
          </div>
          <div class="modal-body">
            <h5>{{$t('users.tools')}}</h5>
            <ul class="list-group" v-if="listOfTools.length > 0">
              <li class="list-group-item text-left d-flex justify-content-between" v-for="(tool, index) in listOfTools" :key="index">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" :id="tool.id" :ref="tool.id" :checked="tool.data.users.includes(selectedUser)" @change="setAsUser(tool.id, selectedUser)">
                  <label class="form-check-label" :for="tool.id">{{tool.data.name}}</label>
                </div>
                  <SetupClaims :uid="selectedUser" :toolId="tool.id" :name="tool.data.name" :claims="tool.data.claims" v-if="tool.data.claims.length > 1 && tool.data.users.includes(selectedUser)"/>
              </li>
            </ul>

            <h5 class="mt-5">{{$t('users.permission')}}</h5>
            <select class="form-select" aria-label="Berechtigung" id="state" v-model="state" @change="setClaims(state, selectedUser)">
              <option value="false">Nutzer</option>
              <option value="admin" >Admin</option>
              <option value="superadmin" v-if="user.claims.state === 'superadmin'">Super Admin - Developer</option>
            </select>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="modalSttings.hide()">{{$t('users.close')}}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" tabindex="-1" ref="modalDelete">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" >{{$t('users.delete')}}</h5>
            <button type="button" class="btn btn-close" @click="modalDelete.hide()" aria-label="Close" />
          </div>

          <div class="modal-body" v-if="selectedUser">
            <div class="alert alert-warning">{{$t('users.user_delete_message', {user: users.find(el => el.uid === selectedUser)?.displayName} )}}</div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="modalDelete.hide()">{{$t('users.close')}}</button>
            <button type="button" class="btn btn-danger" data-bs-dismiss="modal" style="color: white;" @click="deleteUser()">{{$t('users.delete')}}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" ref="modalCreate">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{$t('users.new_user')}}</h5>
            <button type="button" class="btn btn-close"  @click="modalCreate.hide()" aria-label="Close" />
          </div>
          <div class="modal-body">

            <label for="name" class="form-label fw-bold">{{$t('users.name')}}</label>
            <div class="input-group mb-3">
              <input class="form-control" id="name" v-model="name"/>
            </div>

            <label for="email" class="form-label fw-bold">{{$t('users.email')}}</label>
            <div class="input-group mb-3">
              <input class="form-control" id="email" v-model="email"/>
            </div>

            <label for="state" class="form-label fw-bold">{{$t('users.permission')}}</label>
            <select class="form-select" aria-label="Berechtigung" id="state" v-model="state">
              <option value="false" selected="selected">{{$t('users.user')}}</option>
              <option value="admin" >Admin</option>
              <option value="superadmin" v-if="user.claims.state === 'superadmin'">Super Admin - Developer</option>
            </select>

            <h5 class="mt-5">{{$t('users.lang')}}</h5>
            <select class="form-select" aria-label="Sprache" id="state" v-model="lang">
              <option value="de" selected="selected">{{$t('users.de')}}</option>
              <option value="en" >{{$t('users.en')}}</option>
              <option value="fr" >{{$t('users.fr')}}</option>
            </select>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="createUser()">{{$t('users.create')}}</button>
            <button type="button" class="btn btn-secondary" @click="modalCreate.hide()">{{$t('users.close')}}</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Loading from "./LoadingComponent.vue"
import { getFirestore, doc, setDoc, updateDoc, collection, query, getDocs, orderBy, where, deleteDoc, arrayRemove, arrayUnion} from "firebase/firestore"
import { firebase, region } from "../plugins/firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import { mapGetters } from "vuex";
import { Modal } from "@comlinedev/bootstrap5"
import DropDown from "./smallComponents/DropDownUsers.vue"
import State from "./smallComponents/StateComponent.vue"
import SetupClaims from "./smallComponents/setupClaims.vue"
import { orderBy as ldOrderBy } from "lodash-es";

export default {
  name: 'UsersComponent',
  components: {
    Loading,
    DropDown,
    State,
    SetupClaims
  },  
  computed: {
    ...mapGetters({
      user: "user"
    }),
  },
  data() {
    return {
      listOfTools: [],
      loading: true,
      selectedUser: null,
      selectedUserName: "",
      error: null,
      users: [],
      name: "",
      email: "",
      state: "false",
      lang: "de",
      search: ""
    };
  },
  methods: {
    reload() {
      this.loading = true;
      setTimeout(()=>{this.loadData(); this.loading = false}, 1000);
    },
    async loadData(){
      let getUsersFromAuth = httpsCallable(getFunctions(firebase, region), 'getUsers');
      await getUsersFromAuth()
      .then((data)=>{
        this.users = ldOrderBy(data.data.users, ["displayName"], ["asc"]);
      })
      .catch(err=>{      
        console.error(err)
        this.$store.commit('addError', err)
      })
        console.log(this.users)
      return 'ok'
    },
    openModalCreate(){
      this.name = '' 
      this.url = ''
      this.claims = []
      this.describe = ''
      this.modalCreate.show()
    },
    async createUser(){

      let createUserAccount = await httpsCallable(getFunctions(firebase, region), 'createUser');

      await createUserAccount({email: this.email, name: this.name, state: this.state, lang: this.lang})
      .then(()=>{
        this.$store.commit('addSuccess', `Der Nutzer ${this.name} wurde erstellt und bekommt seine Zugangsdaten per E-Mail.`)
        this.modalCreate.hide()
        //daten nach 1 Sekunden laden
        this.name = null
        this.email = null
        this.state = false
        this.lang = 'de'
        this.reload()
      })
      .catch(err=>
        this.$store.commit('addError', err)
      )
    },
    async deleteUser(){
      let deleteUserAccount = await httpsCallable(getFunctions(firebase, region), 'deleteUser');
      await deleteUserAccount({uid: this.selectedUser})
      .then(()=>{
        this.$store.commit('addSuccess', `Der Nutzer ${this.users.find(el => el.uid === this.selectedUser)?.displayName} wurde gelöscht.`)
        this.reload()
      })
      .catch(err=>{      
        console.error(err)
        this.reload()
      })

    },
    async passwordUser(){
      let resetPassword = await httpsCallable(getFunctions(firebase, region), 'sendPasswordReset');
      await resetPassword({uid: this.selectedUser})
      .then(()=>{
        this.$store.commit('addSuccess', `Dem Nutzer ${this.users.find(el => el.uid === this.selectedUser)?.displayName} wurde eine E-Mail zum zurücksetzen seines Passworts gesendet.`)
      })
      .catch(err=>{      
        console.error(err)
      })

    },
    async getListOfTools(){
      const qOnlyMy = query(collection(getFirestore(), "tools"), where("users", "array-contains", this.user.data.uid), orderBy("name"));
      const qAll = query(collection(getFirestore(), "tools"), orderBy("name"));

      const q = this.user.claims.state === 'superadmin'? qAll : qOnlyMy;
      const querySnapshot = await getDocs(q);
      this.listOfTools = []
      querySnapshot.forEach((doc) => {
        this.listOfTools.push({data: doc.data(), id: doc.id})
      })
    },
    async setAsUser(tool, user){
      const state = this.$refs[tool][0].checked
      const toolData = this.listOfTools.find(el => el.id === tool).data
      const claimsRequired = toolData.claims.find(el => el?.required)

      if(state){
        updateDoc(doc(getFirestore(), "tools", tool), {users: arrayUnion(user)})
        setDoc(doc(getFirestore(), "tools", tool, "claims", user),{[claimsRequired.key]: claimsRequired.value})
      } else {
        updateDoc(doc(getFirestore(), "tools", tool), {users: arrayRemove(user)})
        deleteDoc(doc(getFirestore(), "tools", tool, "claims", user))
      }
      this.getListOfTools()
    },
    async setClaims(state, uid){
      let setUserCustomClaims = await httpsCallable(getFunctions(firebase, region), 'setUserCustomClaims');
      await setUserCustomClaims({uid: uid, state: state})
      .then(()=>{
        this.reload()
      })
      .catch(err=>
        this.$store.commit('addError', err)
      )
    }
  },
  mounted(){
    this.getListOfTools()
    this.loadData()
    .then(()=>{
      this.loading = false
    })
    this.modalSttings = new Modal(this.$refs.modalSttings)
    this.modalDelete = new Modal(this.$refs.modalDelete)
    this.modalCreate = new Modal(this.$refs.modalCreate)
  }
}
</script>

<style scoped>
.list-group-item-action { 
  cursor: pointer;
}
.list-group-item:hover {
  background-color: #e9ecef;
}
</style>