<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-primary" style=" position: fixed; width: 100%; color: white; z-index: 999;">
    <div class="container">
      
      <a class="navbar-brand d-flex" href="https://portal.comline.app">
        <Logo :fill="'#ffffff'" :height="'33px'" :width="'33px'" class="mb-0 me-2 d-flex align-items-center"></Logo> <span id="logotext"> Portal</span>
      </a>

      <div class="collapse navbar-collapse" id="navbarSupportedContent" >
        <ul class="navbar-nav me-auto mb-2 mb-lg-0" v-if="user?.loggedIn && $route.meta.requiresAuth && user.claims.active">
          <li :class="`nav-item just-link ${$route.path.includes('/home') ? 'active' : ''}`">
            <router-link  :class="`nav-link`" :to="`/home`">{{$t('navbar.tools')}}</router-link >
          </li>

          <li :class="`nav-item just-link  ${$route.path.includes('settings') || $route.path.includes('settings') ? 'active' : ''}`" v-if="user.claims.state === 'admin' || user.claims.state === 'superadmin'">
            <router-link  :class="`nav-link`" :to="`/settings`">{{$t('navbar.settings')}}</router-link >
          </li>

          <li :class="`nav-item just-link  ${$route.path.includes('users') || $route.path.includes('users') ? 'active' : ''}`" v-if="user.claims.state === 'admin' || user.claims.state === 'superadmin'">
            <router-link  :class="`nav-link`" :to="`/users`">{{$t('navbar.usermanagement')}}</router-link >
          </li>

        </ul>
      </div>
      <div class="text-right" style="min-width: 5px; text-align: right;">
        <Lang />
      </div>
      <div class="text-right h-100" style="min-width: 50px; text-align: right;" v-if="user?.loggedIn && $route.meta.requiresAuth">
        <DropDownAccount @logout="logOut()" @edit="edit('edit')"/>
      </div>
    </div>
  </nav>

    <div class="modal" ref="userEditModal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{$t('user.my_data')}}</h5>
            <button type="button" class="btn btn-close"  @click="userEditModal.hide()" aria-label="Close"/>
          </div>
          
          <div class="modal-body" >

            <label for="name" class="form-label fw-bold">{{$t('user.name')}}</label>
            <div class="input-group mb-3">
              <input class="form-control" id="name" v-model="name"/>
            </div>

            <label for="email" class="form-label fw-bold">{{$t('user.email')}}</label>
            <div class="input-group mb-3">
              <input class="form-control" id="email" disabled v-model="email"/>
            </div>

          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="saveUserData()">{{$t('user.save')}}</button>
            <button type="button" class="btn btn-secondary" @click="userEditModal.hide()">{{$t('user.close')}}</button>
          </div>
        </div>
      </div>
    </div>

</template>


<script>
import Logo from '../assets/Logo'
import { mapGetters } from "vuex";
import { getAuth, signOut, updateProfile } from "firebase/auth"
import Lang from "../components/smallComponents/LangComponent"
import DropDownAccount from "./smallComponents/DropDownAccount.vue"
import { Modal } from "@comlinedev/bootstrap5"
import store from "../plugins/store";

export default {
  name: "NavigationComponent",
  components: {
    Logo,
    Lang,
    DropDownAccount
  },
  computed: {
    ...mapGetters({
      user: "user"
    }),
  },
  watch: {
    user: {
      handler(newValue) {
      this.name = newValue.claims.name;
      this.email = newValue.claims.email;
      },
      deep: true
    }
  },
  data(){
    return{
      name: "",
      email: "",
    }
  },
  methods: {
    edit(){
      this.userEditModal.show()
    },
    saveUserData(){
      updateProfile(getAuth().currentUser, {
        displayName: this.name
      })
      .then(()=>{
        this.$store.commit('addSuccess', `Dein Name wurde zu "${this.name}" geändert.`)
        let newUser = this.user
        newUser.claims.name = this.name
        store.dispatch("fetchUser", newUser)
        this.userEditModal.hide()
      })
      .catch((error)=>{
        this.$store.commit('addError', error)
      })
    },
    logOut(){
      signOut(getAuth()).then(() => {
        this.$router.push('login')
      }).catch((error) => {
        console.error(error)
      });
    },
  },
  mounted(){
    this.userEditModal = new Modal(this.$refs.userEditModal)
  },
};
</script>

<style scoped>
  .dropdown-menu {
    z-index: 2000;
  }
  nav .navbar-brand,
  .navbar-collapse>ul>li>a,
  .logout {
    color: white !important; 
    text-decoration: none;
  }
  li.dropdown.active>a,
  li.just-link.active>a {
    color: #4089c9 !important;
    background-color: #ffffff;
    border: 1px solid rgb(255, 255, 255);
    border-radius: .25rem;
  }
  li.dropdown, 
  li.just-link {
    padding: 0px 3px;
  }
  .clickable {
    cursor: pointer;
  }
  .clickable:hover {
    color: darkgray;
  }
</style>