<template>
  <div class="dropdown">
    <button class="btn btn-link btn-sm" style="padding: 0 !important;" type="button" :ref="`dropdownMenu${id}`" data-bs-toggle="dropdown" @click="dropDown.toggle()" aria-expanded="false">
    <font-awesome-icon :icon="['fas', 'ellipsis-h']" color="grey"/> 
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton" >
      <li><span class="dropdown-item cpt" @click="this.$emit('edit', id)">{{$t('users.edit')}}</span></li>
      <li><span class="dropdown-item cpt" @click="this.$emit('password', id)">{{$t('users.set_password_text')}}</span></li>
      <li><span class="dropdown-item cpt" @click="this.$emit('delete', id)">{{$t('users.delete')}}</span ></li>
    </ul>
  </div>
</template>

<script>
import { Dropdown } from "@comlinedev/bootstrap5"

export default {
  name: 'dropdownUsers',
  props:{
    id: String
  },
  methods:{
  },
  emits: ['delete', 'edit', 'password'],
  data(){
    return {
      dropDown: null
    }
  },
  mounted(){
    this.dropDown = new Dropdown(this.$refs[`dropdownMenu${this.id}`])
  }
}
</script>

<style scoped>
  .cpt {
    cursor: pointer;
  }
</style>
