<template>
  <small>
    <span class="badge bg-secondary" v-if="state === 'false'">User</span>
    <span class="badge bg-primary" v-else-if="state === 'superadmin'">Superadmin</span>
    <span class="badge bg-dark" v-else-if="state === 'admin'">Admin</span>
    <span v-else><BeatLoader :color="'rgba(64, 137, 201, 1)'" :size="'10px'" class="mt-1"/></span>
  </small>
</template>

<script>
import BeatLoader from 'vue-spinner/src/BeatLoader.vue';
  export default {
    name: 'stateLable',
    components: {
      BeatLoader
    },
    props:{
      state: String
    }
  }
</script>
