import { createStore } from "vuex";
import detectBrowserLanguage from 'detect-browser-language'

export default createStore({
  state: {
    user: {
      loggedIn: false,
      claims: false,
      data: null
    },
    data: false,
    lang: ()=>{
      console.log(detectBrowserLanguage().split('-')[0])
      let lang = detectBrowserLanguage().split('-')[0]
      if (lang !== 'de' && lang !== "fr") return "gb"
      return lang
    },
    loading: true,
    settings: {},
    errors: [],
    successes: [],
  },
  getters: {
    user(state){
      return state.user
    },
    data(state){
      return state.data
    },
    loading(state){
      return state.loading
    },
    settings(state){
      return state.settings
    }
  },
  mutations: {
    addError(state, error) {
      state.errors.push(error);
      setTimeout(() => {
        state.errors = state.errors.filter(item => item !== error)
      }, "3000")
    },
    addSuccess(state, success) {
      state.successes.push(success);
      setTimeout(() => {
        state.successes = state.successes.filter(item => item !== success)
      }, "3000")
    },
    errors(state, errors) {
      state.errors = errors;
    },
    successes(state, successes) {
      state.successes = successes;
    },
    alertSuccess(state, alertSuccess) {
      state.alertSuccess = alertSuccess;
    },
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER_CLAIMS(state, val) {
      state.user.claims = val;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    async fetchUser({ commit }, user) {
      // console.log(user)
      commit("SET_LOADING", true);
      commit("SET_LOGGED_IN", user !== null);
      if (user) {
        const claims = await user.getIdTokenResult()
        commit("SET_USER_CLAIMS", claims?.claims);
        commit("SET_USER", user);
      } else {
        commit("SET_USER", null);
      }
      commit("SET_LOADING", false);
    }
  }
});