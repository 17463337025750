<template>
  <div class="dropdown dropstart">
    <button class="btn btn-link btn-sm" style="padding: 0 !important;" type="button" id="dropdownLang" ref="dropdownLang" data-bs-toggle="dropdown" @click="dropdown.toggle()" aria-expanded="false">
      <country-flag :country='$i18n.locale == "en"?"gb": $i18n.locale' size='normal' :rounded="true" :shadow="true"/>
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownLang" >
      <li><span :class="`dropdown-item ${$i18n.locale === 'de' ? 'active' : ''}`" @click="setLang('de')"><country-flag country='de' size='small'/> Deutsch </span ></li>
      <li><span :class="`dropdown-item ${$i18n.locale === 'en' ? 'active' : ''}`" @click="setLang('en')"><country-flag country='gb' size='small'/> English </span ></li>
      <li><span :class="`dropdown-item ${$i18n.locale === 'fr' ? 'active' : ''}`" @click="setLang('fr')"><country-flag country='fr' size='small'/> Français </span ></li>
    </ul>
  </div>
</template>

<script>
import CountryFlag from 'vue-country-flag-next'
import { Dropdown } from "@comlinedev/bootstrap5"
import { mapGetters } from "vuex";
import { getFirestore, doc, setDoc } from "firebase/firestore"

export default {
  name: 'LangDropdown',
  components: {
    CountryFlag
  },
    computed: {
    ...mapGetters({
      user: "user"
    }),
  },
  data(){
    return {
      dropdown: null
    }
  },
  methods:{
    setLang(lang){
      this.$i18n.locale = lang
      if(this.user?.data?.uid){
        setDoc(doc(getFirestore(), "userLang", this.user.data.uid), {lang: lang})
      }
    }
  },
  mounted(){
    this.dropdown = new Dropdown(this.$refs.dropdownLang)
  }
}
</script>

<style>

</style>
