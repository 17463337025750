<template>
  <div class="container">

    <Loading v-if="loading"  style="margin-top: 25px;"/>
    
    <div class="row mt-5" v-else-if="tools.length <= 0">
      <div class="alert alert-warning" role="alert">
        {{$t('settings.no_tools_message')}}
      </div>
      <a href="#" class="btn btn-primary mt-5"  v-if="user.claims.state === 'superadmin'" @click="openModalCreate()"><font-awesome-icon class="" :icon="['fas', 'plus']" /> {{$t('settings.new_tool')}}</a>
    </div>

    <template v-else>

      <div class="row mt-5" >
        <a href="#" class="btn btn-primary mb-5"  v-if="user.claims.state === 'superadmin'" @click="openModalCreate()"><font-awesome-icon class="" :icon="['fas', 'plus']" />  {{$t('settings.new_tool')}}</a>
      </div>

      <div class="row mt-5" >
        <div class="col col-md-6 col-xl-3 col-12 mb-4" v-for="(item, index) in tools" :key="index">
          <div class="card text-center" style="overflow: hidden;">
            <div style="position: relative;" >
              <ImageBlob class="card-image" :data="{image: item.data?.image}"/>
            </div>
            <div class="card-body" style="position: relative; margin-top: 80px;">
              <div class="card-text">
                <h4>{{item.data.name}}</h4>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item list-group-item-action" v-if="user.claims.state === 'superadmin'" @click="openSettings(item)">{{$t('settings.settings')}}</li>
                  <li class="list-group-item list-group-item-action" v-if="user.claims.state === 'superadmin'" @click="(selectedTool=item.id) && modalDelete.show()">{{$t('settings.delete')}}</li>
                  <li class="list-group-item list-group-item-action" v-if="user.claims.state === 'superadmin' || user.claims.state === 'admin'" @click="(selectedTool=item.id) && modalUsers.show()">{{$t('settings.user')}} ({{item.data?.users?.length}})</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div class="modal" ref="modalSttings">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{$t('settings.settings')}}</h5>
            <button type="button" class="btn btn-close"  @click="modal.hide()" aria-label="Close" />
          </div>
          <div class="modal-body">

            <label for="name" class="form-label fw-bold">{{$t('settings.name')}}</label>
            <div class="input-group mb-3">
              <input class="form-control" id="name" v-model="name"/>
            </div>

            <label for="url" class="form-label fw-bold">{{$t('settings.url')}}</label>
            <div class="input-group mb-3">
              <input class="form-control" id="url" v-model="url"/>
            </div>

            <div class="mb-3">
            <label class="form-label fw-bold" for="preview">{{$t('settings.preview')}}</label>
            <div class="input-group" ref="image">
              <input type="file" class="form-control" id="preview" accept=".gif, .jpg, .png" @change="uploadImage($event)">
              <label v-if="imageName" class="input-group-text" for="preview">{{imageName}}</label>
            </div>
            <small>.png, .jpg, .gif</small>
            <ImageBlob v-if="imagePath" :data="{image: imagePath}"/>
            </div>

            <label for="textarea" class="form-label fw-bold">{{$t('settings.admin_key')}}</label><br>
            <small class="text-danger mt-0">{{$t('settings.admin_key_message')}}</small>
            <div class="input-group mb-3">
              <textarea class="form-control" id="textarea" v-model="jsonToken" rows="3"></textarea>
            </div>

            <label class="form-label fw-bold">{{$t('settings.claims')}}</label>
            <div class="input-group mb-3" v-for="(data, index) in claims" :key="index">
              <div class="input-group-text">{{$t('settings.default')}} &nbsp;
                <input class="form-check-input mt-0" type="checkbox" v-model="claims[index].required" aria-label="Erforderlich">
              </div>
              <span class="input-group-text">{{$t('settings.key')}}</span>
              <input type="text" aria-label="key" class="form-control" v-model="claims[index].key">
              <span class="input-group-text">{{$t('settings.value')}}</span>
              <input type="text" aria-label="value" class="form-control" v-model="claims[index].value">
              <button class="btn btn-danger" @click="deleteThisClaim(index)">{{$t('settings.delete')}}</button>
            </div>

            <div>
              <button type="button" class="btn btn-success" @click="claims.push({key: '', value: ''})"><font-awesome-icon class="" :icon="['fas', 'plus']" /> </button>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="saveSettings()">{{$t('settings.save')}}</button>
            <button type="button" class="btn btn-secondary" @click="modal.hide()">{{$t('settings.close')}}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" tabindex="-1" ref="modalDelete">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" >{{$t('settings.delete')}}</h5>
            <button type="button" class="btn btn-close" @click="modalDelete.hide()" aria-label="Close" />
          </div>

          <div class="modal-body" v-if="selectedTool">
            <div class="alert alert-warning">{{$t('settings.delete_message', { name: tools.find(el => el.id === selectedTool)?.data.name })}}</div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="modalDelete.hide()">{{$t('settings.close')}}</button>
            <button type="button" class="btn btn-danger" data-bs-dismiss="modal" style="color: white;" @click="deleteTool(selectedTool)">{{$t('settings.delete')}}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" ref="modalUsers">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{$t('settings.user')}}</h5>
            <button type="button" class="btn btn-close"  @click="modalUsers.hide()" aria-label="Close"/>
          </div>
          
          <div class="modal-body" v-if="selectedTool" >
            <ul class="list-group">
              <li class="list-group-item text-left d-flex justify-content-between" v-for="(toolUser, index) in users" :key="index">

                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" :id="toolUser.uid" :ref="toolUser.uid" :checked="tools.find(el => el.id === selectedTool)?.data.users.includes(toolUser.uid)" @change="setAsUser(selectedTool, toolUser.uid)" :disabled="toolUser?.customClaims.state === 'superadmin' && user.claims.state === 'admin'">
                  <label class="form-check-label" :for="toolUser.uid">{{toolUser.displayName}}</label>
                </div>

                <SetupClaims :uid="toolUser.uid" :toolId="selectedTool" :name="toolUser.displayName" :claims="tools.find(el => el.id === selectedTool)?.data.claims" v-if="tools.find(el => el.id === selectedTool)?.data.claims.length > 1 && tools.find(el => el.id === selectedTool)?.data.users.includes(toolUser.uid)"/>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" ref="modalCreateTool">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Neues Tool</h5>
            <button type="button" class="btn btn-close"  @click="modalCreateTool.hide()" aria-label="Close" />
          </div>
          <div class="modal-body">

            <label for="name" class="form-label fw-bold">Name</label>
            <div class="input-group mb-3">
              <input class="form-control" id="name" v-model="name"/>
            </div>

            <label for="url" class="form-label fw-bold">URL</label>
            <div class="input-group mb-3">
              <input class="form-control" id="url" v-model="url"/>
            </div>

            <div class="mb-3">
            <label class="form-label fw-bold" for="preview">Vorschau</label>
            <div class="input-group" ref="image">
              <input type="file" class="form-control" id="preview" accept=".gif, .jpg, .png" @change="uploadImage($event)">
              <label v-if="imageName" class="input-group-text" for="preview">{{imageName}}</label>
            </div>
            <small>.png, .jpg, .gif</small>
            <ImageBlob v-if="imagePath" :data="{image: imagePath}"/>
            </div>

            <label for="textarea" class="form-label fw-bold">ADMIN KEY</label>
            <div class="input-group mb-3">
              <textarea class="form-control" id="textarea" v-model="jsonToken" rows="3"></textarea>
            </div>

            <label class="form-label fw-bold">Claims</label>
            <div class="input-group mb-3" v-for="(data, index) in claims" :key="index">
              <div class="input-group-text">Standard &nbsp;
                <input class="form-check-input mt-0" type="checkbox" v-model="claims[index].required" aria-label="Erforderlich">
              </div>
              <span class="input-group-text">Key</span>
              <input type="text" aria-label="key" class="form-control" v-model="claims[index].key">
              <span class="input-group-text">Value</span>
              <input type="text" aria-label="value" class="form-control" v-model="claims[index].value">              
              <button class="btn btn-danger" @click="deleteThisClaim(index)">{{$t('settings.delete')}}</button>
            </div>

            <div>
              <button type="button" class="btn btn-success" @click="claims.push({key: '', value: ''})"><font-awesome-icon class="" :icon="['fas', 'plus']" /> </button>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="createTool()">{{$t('settings.create')}}</button>
            <button type="button" class="btn btn-secondary" @click="modalCreateTool.hide()">{{$t('settings.close')}}</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Loading from "./LoadingComponent.vue"
import { query, collection, orderBy, getFirestore, getDocs, doc, setDoc, updateDoc, addDoc, deleteDoc, where, arrayRemove, arrayUnion} from "firebase/firestore"
import { storage, firebase, region } from "../plugins/firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import { mapGetters } from "vuex";
import { Modal } from "@comlinedev/bootstrap5"
import { ref as storageRef, uploadBytesResumable } from "firebase/storage";
import ImageBlob from "./smallComponents/ImageBlob.vue"
import SetupClaims from "./smallComponents/setupClaims.vue"
import { orderBy as ldOrderBy } from "lodash-es";

export default {
  name: 'SettingsComponent',
  components: {
    Loading,
    ImageBlob,
    SetupClaims
  },  
  computed: {
    ...mapGetters({
      user: "user"
    }),
  },
  data() {
    return {
      loading: true,
      tools: [],
      selectedTool: null,
      error: null,
      jsonToken: null,
      users: [],
      claims: [],
      timestamp: Date.parse(new Date()),
      name: "",
      url: "",
      imageName: "",
      imagePath: ""
    };
  },
  methods: {
    async loadData(){
      const qOnlyMy = query(collection(getFirestore(), "tools"), where("users", "array-contains", this.user.data.uid), orderBy("name"));
      const qAll = query(collection(getFirestore(), "tools"), orderBy("name"));

      const q = this.user.claims.state === 'superadmin'? qAll : qOnlyMy;
      this.tools = []
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        this.tools.push({ id: doc.id, data: doc.data()})
      })

      return 'ok'
    },
    openSettings(item){
      this.imagePath=""
      this.selectedTool=item.id
      this.name=item.data.name
      this.url=item.data.url
      this.claims=item.data.claims
      this.imageName=""
      this.imagePath=item.data.image
      this.modal.show()
    },
    deleteThisClaim(index){
      this.claims.splice(index,1);
    },
    async saveSettings(){

      if(
        this.name == "" ||
        this.url == ""
      ) {
        this.$store.commit('addError', `Bitte fülle mindestens die URL und den Namen aus.`);
        return
      }

      await updateDoc(doc(getFirestore(), "tools", this.selectedTool), {name: this.name, url: this.url, claims: this.claims, image: this.imagePath})
      .catch(err=>this.error=err)

      if (this.jsonToken){
        await setDoc(doc(getFirestore(), "tools", this.selectedTool, 'token', 'data'), JSON.parse(this.jsonToken))
        .then(()=>{

        })
        .catch(err=>{      
          console.error(err)
          this.$store.commit('addError', err)
        })
      }

        this.$store.commit('addSuccess', `Die Änderungen für Das Tool ${this.name} wurde erfolgreich übernommen.`)
        this.modal.hide()
        this.loadData()
        this.jsonToken = null
        this.selectedTool = null
        this.imageName = ""
        this.imagePath = ""
    },
    async getUsers(){
      let getUsersFromAuth = await httpsCallable(getFunctions(firebase, region), 'getUsers');
      await getUsersFromAuth()
      .then((data)=>{
        this.users = ldOrderBy(data.data.users, ["displayName"], ["asc"]);
      })
      .catch(err=>{      
        console.error(err)      
      })
    },
    openModalCreate(){
      this.name = '' 
      this.url = ''
      this.claims = []
      this.imageName = ""
      this.imagePath = ""
      this.modalCreateTool.show()
    },
    uploadImage(data) {
      const image = data.target.files[0];
      const path = `preview/${this.timestamp}/${image.name}`
      const useRef = storageRef(
        storage,
        path
      );
      const uploadTask = uploadBytesResumable(useRef, image);

      uploadTask.on(
        "state_changed",
        () => {
          this.imageName = "Uploading...";
        },
        (err) => {
          this.$store.commit('addError', err)
          this.imageName = "Error";
        },
        () => {
          this.imageName = image.name;
          this.$store.commit('addSuccess', `Das Bild ${this.imageName} wurde erfolgreich hochgeladen.`)
          this.imagePath = path;
        }
      );
    },
    async createTool(){
      if(
        this.name == "" ||
        this.url == ""
      ) {
        this.$store.commit('addError', `Bitte fülle mindestens die URL und den Namen aus.`);
        return
      }

      let docRef = await addDoc(collection(getFirestore(), "tools"), {name: this.name, url: this.url, claims: this.claims, users: [], image: this.imagePath}).catch(err=>{      
        console.error(err)
        this.$store.commit('addError', err)
      });

      if( this.jsonToken ){
        await setDoc(doc(getFirestore(), "tools", docRef.id, 'token', 'data'), JSON.parse(this.jsonToken))
        .then(()=>{
          this.$store.commit('addSuccess', `Das Tool ${this.name} wurde erfolgreich erstellt.`)
          this.modalCreateTool.hide()
          this.loadData()
          this.jsonToken = null
          this.selectedTool = null
          this.imageName = ""
          this.imagePath = ""
        })
        .catch(err=>{      
          console.error(err)
          this.$store.commit('addError', err)
        })
      } else {
          this.$store.commit('addSuccess', `Das Tool ${this.name} wurde erfolgreich erstellt.`)
          this.modalCreateTool.hide()
          this.loadData()
          this.jsonToken = null
          this.selectedTool = null
          this.imageName = ""
          this.imagePath = ""
      }
    },
    async deleteTool(id){
      let recursiveDelete = await httpsCallable(getFunctions(firebase, region), 'recursiveDelete');
      await recursiveDelete({id: id})
      .then(()=>{
        this.$store.commit('addSuccess', `Das Tool ${this.name} wurde erfolgreich gelöscht.`)
        this.loadData()
      })
      .catch(err=>{      
        console.error(err)
        this.$store.commit('addError', err)
        this.loadData()
      })

    },
    async setAsUser(tool, user){
      const state = this.$refs[user][0].checked
      const toolData = this.tools.find(el => el.id === tool).data
      const claimsRequired = toolData.claims.find(el => el?.required)

      if(state){
        !toolData.users.includes(user) && toolData.users.push(user)
        updateDoc(doc(getFirestore(), "tools", tool), {users: arrayUnion(user)})
        setDoc(doc(getFirestore(), "tools", tool, "claims", user),{[claimsRequired.key]: claimsRequired.value})
      } else {
        toolData.users.includes(user) && (toolData.users = toolData.users.filter(uid => uid !== user)); 
        updateDoc(doc(getFirestore(), "tools", tool), {users: arrayRemove(user)})
        deleteDoc(doc(getFirestore(), "tools", tool, "claims", user))
      }
    }
  },
  mounted(){
    this.getUsers()
    this.loadData()
    .then(()=>{
      this.loading = false
    })
    this.modal = new Modal(this.$refs.modalSttings)
    this.modalDelete = new Modal(this.$refs.modalDelete)
    this.modalUsers = new Modal(this.$refs.modalUsers)
    this.modalCreateTool = new Modal(this.$refs.modalCreateTool)
  }
}
</script>

<style scoped>
.list-group-item-action { 
  cursor: pointer;
}
.card{
  height: 100%; 
}
.card-text {
  position: absolute;
  bottom: 0px; 
  width: 100%;
  color: #787878;
  min-height: 120px;
  left: 0px;
  padding-top: 20px; 
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 79%, rgba(255,255,255,0.742734593837535) 88%, rgba(255,255,255,0) 100%);
}
.list-group-flush .list-group-item:first-of-type {
  border-top: 1px solid rgba(0,0,0,.125);
}
</style>